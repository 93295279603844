import React from 'react'
import { ThemeProvider } from 'styled-components'

import Peracto from '@peracto/peracto'
import Config from '@peracto/peracto-config'
import PeractoUser from '@peracto/peracto-user'
import PeractoCatalogue from '@peracto/peracto-catalogue'
import PeractoCommon from '@peracto/peracto-common'
import PeractoContent from '@peracto/peracto-content'
import PeractoLocations from '@peracto/peracto-locations'
import PeractoPromotions from '@peracto/peracto-promotions'
import { OrdersList, OrdersView } from './packages/Catalogue/Orders'

import { Carbon } from '@peracto/fe-library'

import theme from './theme'
import blocks from './blocks'

const config = new Config({
    api: process.env.REACT_APP_API,
    algolia: {
        applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
        searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
        indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
    },
    modules: [
        PeractoUser(),
        PeractoCommon(),
        PeractoCatalogue({ OrdersList, OrdersView }),
        PeractoLocations(),
        PeractoPromotions(),
        PeractoContent(),
    ],
    editorConfig: {
        theme: Carbon.Theme,
        colorOptions: [
            { label: 'Brand Green', value: Carbon.Theme.colors.brand.green },
            { label: 'Brand Red', value: Carbon.Theme.colors.brand.red },
        ],
        locales: ['en_GB', 'es_ES', 'fr_FR'],
        blocks,
        inputTypes: {},
    },
    features: {
        shippingServices: {
            deliveryDays: true,
        },
    },
})

const App = () => (
    <ThemeProvider theme={theme}>
        <Peracto config={config} />
    </ThemeProvider>
)

export default App
